import React, { useState, useEffect } from 'react';
import { Banner, Column, Columns, Container, Heading, Inner } from '../components/ui';
import { apiRequestOptions, getApiUrl } from '../functions/Config';
import ReactMarkdown from 'react-markdown';
import styles from './Views.module.scss';
import map from '../media/vatterfisk_map.jpg';

const Default = ({slug}) => {
    const [content, setData] = useState(false);
    const [isLoaded, setLoadingState] = useState(false);

    // console.log('slug '+ slug);

    useEffect(() => {
        const abortController = new AbortController();


        setLoadingState(false);

        const apiUrl = getApiUrl("Pages");
        const fetchData = async () => { 
            fetch(apiUrl,apiRequestOptions)
            .then(res => res.json())
            .then(res => {
                const data = res.records.filter((page)=> page.fields.slug === '/'+slug);
                setData(data[0])
                return true;
                
            })
            
            .catch(error => console.log(error))
        }

        fetchData().then(function () {

            setTimeout(function () {
                setLoadingState(true);
                window.scrollTo({ top: 0 });

            }, 500)
        })

        return function cleanup() {
            abortController.abort();
        }
        
    },[slug])

    const ContentMarkup = () => (
        <>
            {content.fields.Subtitle &&
                <h2 className="title--largest">{content.fields.Subtitle}</h2>
            }
            
            {slug === "kontakt" ?
                <Columns size="3" space className="no-margin-bottom">
                    <Column>
                        <div className={content.fields['Content align'] || ''}>
                            <ReactMarkdown className="markdown">{content.fields.Content}
                                
                            </ReactMarkdown>
                        </div>
                    </Column>
                    <Column fill>
                        <img className={styles.map} src={map} alt="" />
                    </Column>
                </Columns>
                :

                <div className={content.fields['Content align'] || ''}>
                    <ReactMarkdown>
                        {content.fields.Content}
                    </ReactMarkdown>
                </div>
            }
        </>
    )
    
    return (

        <div className={`${styles['default' + (isLoaded ? '--loaded': '')]}`}>
            {content &&
            <>
                {content.fields['Banner Image'] ?
                <>
                <Banner title={content.fields.Title || content.fields.Name} image={content.fields['Banner Image'] ? content.fields['Banner Image'][0].url : false }>
                    
                    {slug !== "kontakt" &&
                        <ContentMarkup />
                    }

                </Banner>
                {slug === "kontakt" && 
                <Container>
                    <Inner y>
                        <ContentMarkup />
                    </Inner>
                </Container>
                }
                </>
                :
                <Heading key={window.location.pathname}>
                    
                    <h1>
                    {content.fields.Title || content.fields.Name}
                    </h1>

                    <ContentMarkup />
                    
                </Heading>
                }

                {content.fields.Images &&
                <Container>
                    <div className="images">
                    {content.fields.Images.map((image, key) => (
                        <figure key={`ìmage-${key}`}>
                            <img src={image.url} alt="img"/>
                        </figure>
                    ))}
                    </div>
                </Container>
                }
            </>
            }
        </div>
    );
};

export default Default;