import logo from './media/vatterfisk_logo_darkblue.svg';
import './App.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams
} from "react-router-dom";
import {Main, Column, Header, Footer} from './components/ui/';

import React from 'react';
import {apiRequestOptions, getApiUrl} from './functions/Config';

import Start from './views/Start';
import Default from './views/Default';
import {IconFacebook, IconInstagram} from './components/ui/utils/Icons';

function App() {
  // const [cookies, setCookie] = useCookies(['order-in-cart']);
  // const [products, assignProducts] = React.useState([]);

  const [pages, setPages] = React.useState([]);
  // const [cartItems, updateCart] = React.useState(cookies['order-in-cart'] || []);
  const [menuOpen, toggleMenu] = React.useState(false);

  React.useEffect(() => {

    const abortController = new AbortController();


    if (pages?.length < 1) {

     

    const apiPages = getApiUrl("Pages");
    fetch(apiPages,apiRequestOptions)
      .then(res => res.json())
      .then(res => {
        setPages(res.records)
      })
      .catch(error => console.log(error))
    }

    return function cleanup(){
      abortController.abort();
    }

  }, [pages?.length]);

  const DefaultTemplate = () => {
    let { slug } = useParams();
    return (
      <Default slug={slug} />
    )
  }
  
  return (
    <Router>
      <div className={`${menuOpen ? 'menu-open' : ''}`}>
      <HelmetProvider>
        <Main>
          <Helmet>
            <title>Vätterfisk</title>
          </Helmet>
          <Header
            logo={logo}
            menuItems={pages}
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
          />

          <Routes>
            
            <Route exact path="/" element={<Start />}/>

            <Route path="/:slug" element={<DefaultTemplate />}/>

            
           
          </Routes>
          

          <Footer id="kontakt">
              <Column>
                <h3>Adress</h3>
              <p>Trädgårdsgatan 9 <br/>
              553 16 JÖNKÖPING <br/>
              Telefon 036-16 61 00</p>
              </Column>
              <Column>
                <h3>Öppettider</h3>
              <p>Mån - Fre: 09.00 - 18.00 <br/>
              Lör 09.00-13.00</p>
              </Column>

              <Column>
                <a href="https://www.facebook.com/pages/category/Fishing-Store/V%C3%A4tterfisk-100700441586539/"><IconFacebook />Facebook</a>
                <a href="https://www.instagram.com/vatterfiskijonkoping/"><IconInstagram />Instagram</a>
              </Column>

          </Footer>
        </Main>
      </HelmetProvider>
      </div>
    </Router>
  );
}

export default App;

