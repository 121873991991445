import React, { useState } from 'react';
import Promo from '../components/Promo/Promo';
import {  Container, Banner} from '../components/ui';
import { apiRequestOptions, getApiUrl } from '../functions/Config';
import styles from './Views.module.scss';
import bannerImage from '../media/vatterfisk-banner.jpg';
import wave from '../media/wave.svg';

const Start = () => {

    const [promos, setData] = useState([]);
    const [isLoaded, setLoadingState] = useState(false);
    
    // base('Content').select({
    //     view: 'Grid view'
    // }).firstPage(function (err, records) {
    //     if (err) { console.error(err); return; }
    //     // setData(records)
    //     records.forEach(function (record) {
    //         console.log('Retrieved', record.get('Name'));
    //     });
    // });

    React.useEffect(() => {
        const apiUrl = getApiUrl("Promos", "Gallery");
        const fetchData = async () => {
            fetch(apiUrl, apiRequestOptions)
            .then(res => res.json())
            .then(res => {
                setData(res.records)
            })
            .catch(error => console.log(error))
        }

        fetchData().then(function () {
            setTimeout(function () {
                setLoadingState(true);
            }, 500)
        })

    }, [])

    return (
        <div className={`${styles['default' + (isLoaded ? '--loaded' : '')]}`}>

            <Banner title="Fisk över disk sedan 1984." image={bannerImage}>
                <p>Man blir smart av fisk, sägs det. Ett påstående som vi inte hundra procent kan garantera stämmer. Men en sak är säker: Vi blir glada av fisk.</p>
                <img className="wave" src={wave} alt="våg"/>
            </Banner>
            <Container>
                
                    
                    

            </Container>
            <div className="promos">
                {promos?.map((promo, i) => {
                    // console.log('promo: ', promo);
                    const title = promo.fields['Title'];
                    const text = promo.fields['Text'];
                    const reverse = promo.fields['Content reverse'];
                    const media = promo.fields['Attachments'] || false;
                    const link = promo.fields['slug (from Pages)'] ? promo.fields['slug (from Pages)'][0] : false;
                    return (

                        <Promo key={`promo-${i}`}
                            title={title}
                            text={text}
                            media={media}
                            reverse={reverse}
                            link={link || false}
                            linkText={"Beställ här"} />

                    )

                })}
            </div>

        </div>
    );
};

export default Start;