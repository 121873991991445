
const Config = {
    endpointUrl: 'https://api.airtable.com',
    // apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    apiKey: process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN,
    base: 'appRuIJs3uv57GwtH'
}

const getApiUrl = (table = "Products", view ="Grid%20view") => {

    let config = {...Config};
    config.table = table;
    // const _url = config.endpointUrl + '/v0/' + config.base + '/' + config.table + '?view=' + view + '&api_key=' + Config.apiKey;
    const _url = config.endpointUrl + '/v0/' + config.base + '/' + config.table ;

    return (
        _url
    );
};

 var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN}`);

export const apiRequestOptions = {
method: 'GET',
headers: myHeaders,
redirect: 'follow'
}

const airtableBase = () => {
    var Airtable = require('airtable');
    // Airtable.configure({
    //     endpointUrl: Config.endpointUrl,
    //     apiKey: Config.apiKey
    // });

    // var base = Airtable.base(Config.base);
    // var base = new Airtable({apiKey:Config.apiKey}).base(Config.base);
    var base = new Airtable({apiKey:Config.apiKey}).base(Config.base);

    return base;
}

export {
    Config,
    airtableBase,
    getApiUrl
};