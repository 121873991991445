import React, { useEffect, useState } from 'react';  
import { Link, NavLink } from 'react-router-dom';
import { IconBurger, IconClose, IconLogo, IconLogoSmall } from '../utils/Icons';
import styles from './Layout.module.scss';
import menuStyles from './Menu.module.scss';

const alignDefault = "center";

const Flex = ({ direction = "row", align = alignDefault, justify = alignDefault, wrap="wrap", flex="none", width="100%", className=null, children}) => {
    const flexstyles = {
        display: "flex",
        flexDirection: direction,
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap,
        flex: flex,
        width: width
    }
    return (
        <div style={flexstyles} className={className}>
            {children}
        </div>
    )
}

const Box = ({children, className=null, size, props}) => (
    <div className={`${size? styles["box--" + size] : styles.box} ${className}`} {...props}>
        {children}
    </div>
)

const FluidBox = ({children, className=null, props}) => (
    <div className={`${styles['padding-fluid']} ${className}`} {...props}>
        {children}
    </div>
)

const Container = ({children}) => (
    <div className={`${styles.container}`}>
        {children}
    </div>
)

const Inner = ({ children, x, y, className }) => {
    let specific = '';

    if (x && !y) {
        specific = "--x";
    }
    if (!x && y) {
        specific = "--y";
    }

    return (
        <div className={`${className} ${styles[`inner${specific}`]}`}>
            {children}
        </div>
    )
}

const Banner = ({ title, children, image }) => (

    <div className={styles.banner}>
        {image &&
            <div className={styles.bannerImage}>
                <img src={image} alt={title} />
                <h1 className={styles.bannerTitle}>{title}</h1>

            </div>
        }
        { children &&
        <div className={styles.bannerContent}>
            <Container>
                <Heading>
                    {!image &&
                        <h1 className={styles.bannerTitle}>{title}</h1>
                    }
                    {children}
                </Heading>
            </Container>
        </div>
        }
    </div>
)

const Main = ({children}) => (
    <div className={`${styles.main}`}>
        {children}
    </div>
)

const Columns = ({ children, size = "2", space=false, stretch, className}) => {
    let classNames= !size ? `${styles.columns}` : `${styles['columns--' + size]}`;
    classNames += space ? ` ${styles["columns--space"]}` : "";

    return (
        <div className={`${classNames} ${className} ${stretch ? styles['stretch'] : ''}`}>
            {children}
        </div>
    )
}

const Column = ({children, size=false, className, fill}) => (
    <div className={`${!size ? styles.column : styles['column--' + size]} ${fill ? styles.columnFill : ""} ${className}`}>

        {children}
    </div>
)


const Menu = ({menuItems, toggleMenu}) => {
 
    return(

        <div className={menuStyles.menu}>
            <nav className={menuStyles.menuNav}>
                <div className={menuStyles.menuMain}>
                    {menuItems.map((item, i) => {
                        const linkText =  item.fields.Name;

                        return (
                            <NavLink key={`nav-link-${i}`} to={item.fields.slug} onClick={toggleMenu} >{linkText}</NavLink>
                        )
                    })}
                </div>
            </nav>
        </div>
    )
}
// const Menu = ({menuItems}) => {
//     function scrollToID(e, id){
//         e.preventDefault();
//         console.log(id.replace('#', ''));
//         const el = document.getElementById(id.replace('#', ''));

//         if (el) {
//             const posY = el.offsetTop;
//             window.scrollTo({ top: posY, behavior: 'smooth' });
//         }

//     };
//     return(

//         <div className={menuStyles.menu}>
//             <nav className={menuStyles.menuNav}>
//                 <div className={menuStyles.menuMain}>
//                     {menuItems.map((item, i) => {
//                         const linkText =  item.title;

//                         return (
                            
//                                 <a key={`menu-link-${i}`} href={item.href} onClick={(item.href.includes("#")) ? (e)=>scrollToID(e, item.href) : undefined}>
//                                    {linkText}
//                                 </a>
                            
//                         )
//                     })}
//                 </div>
//             </nav>
//         </div>
//     )
// }

const ToggleMenu = ({ menuOpen, onClick }) => (
    <div onClick={onClick} className={styles.iconBtn}>
        {!menuOpen ? <IconBurger /> : <IconClose onClick={onClick} />}
    </div>
)

const Header = ({ children, logo, menuItems = false, subMenuItems = false, toggleMenu, menuOpen, className, hide, scrollDistance = 20 }) => {
    const [showSticky, toggleSticky] = useState(true);
    const tolerance = { down: scrollDistance, up: (scrollDistance * -1) };
    let prev = null;

    function checkScrollDirectionIsUp(event) {
        if (event.deltaY > tolerance.down) {
            prev = false;
            return false;
        }
        else if (event.deltaY < tolerance.up) {
            prev = true;
            return true;
        }
        else {
            return prev
        }
    }

    async function handleScroll(e) {

        const dirUp = await checkScrollDirectionIsUp(e);
        toggleSticky(dirUp);

    }

    useEffect(() => {
        document.body.addEventListener('wheel', handleScroll);
    })

    return (
        <div className={`${styles.topHeader} ${styles['sticky' + (showSticky ? '' : (hide ? '--hide' : '--minimize'))]} ${className ? className : ''}`}>

            <Container>
    
                <div className={styles.topHeaderContent}>

                    <Link to="/" className={styles.headerLogo}>
                        <IconLogo/>
                        <IconLogoSmall/>
                    </Link>
                    {children}
                    {menuItems &&
                        <Menu menuItems={menuItems} subMenuItems={subMenuItems} menuOpen={menuOpen} toggleMenu={() => toggleMenu(false)} />
                    }
                    <ToggleMenu onClick={() => toggleMenu(!menuOpen)} menuOpen={menuOpen} />

                </div>
            </Container>
        </div>
    )
}

const Heading = ({children}) => (
    <Flex direction="column" className={styles.heading}>
        {children }
    </Flex>
)

const Footer = ({ children, className, id, logo = "", menuItems = false }) => (
    // console.log('menuitems: ', menuItems),

    <footer id={id} className={`bg--dark ${styles.footer} ${className}`}>
        <Container>

            <Columns size="3" space>

                <Column>

                    <a href="/"><IconLogo /></a>
          </Column>

                    {children}
                    

                </Columns>

        </Container>
    </footer>
)

export {
    Flex,
    Container,
    Inner,
    Columns,
    Column,
    Banner,
    Box,
    FluidBox,
    Heading,
    Header,
    Footer,
    Main
};