import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Column, Columns, Container, Flex, Inner, LinkBtn } from '../ui';
import styles from './Promo.module.scss';
import badge from '../../media/vatterfisk_emblem.svg';

const Promo = ({title, text, ...props}) => {

    return (
            <>
                {props.media ?

                    <Columns className={`${styles.promo} ${props.reverse ? styles.reversed : ''}`}size="2" fill stretch>

                            <Column >

                                {props.link ?
                                    <Link to={props.link} >
                                        <div className={styles.mediaWrapper}>
                                            {props.media[0].type.includes('video') ?
                                                <video muted loop playsInline autoPlay src={props.media[0].url} />

                                                :
                                                <img src={props.media[0].thumbnails.large.url} alt={title} />
                                            }
                                        </div>
                                    </Link>
                                    :
                                    <>
                                    <div className={styles.mediaWrapper}>
                                        {props.media[0].type.includes('video') ?
                                            <video muted loop playsInline autoPlay src={props.media[0].url} />

                                            :
                                            <img src={props.media[0].thumbnails.large.url} alt={title} />
                                        }
                                    </div>
                                    </>
                                }

                                
                            </Column>
                            <Column className={styles.promoContent}>
                                <Inner>
                                    <Flex direction="column">
                                        {props.link ? 
                                        <Link to={props.link} >
                                            <h2 className="title--medium">{title}</h2>
                                            {text &&
                                                <p>{text}</p>
                                            }
                                        </Link>
                                        :
                                        <>
                                            <h2>{title}</h2>
                                            {text &&
                                                <p>{text}</p>
                                            }
                                        </>
                                        }
                                    </Flex>
                                </Inner>
                            </Column>
                        </Columns>

                    :
                    <Container>
                        <Inner y>
                            <div className={styles.promoSingleContent}>


                                <h2>{title}</h2>
                                <ReactMarkdown>{text}</ReactMarkdown>


                                {props.link &&
                                    <LinkBtn href={props.link} inline>
                                        {props.linkText || "Läs mer"}
                                    </LinkBtn>
                                }

                                <figure className={styles.badge}>
                                    <img src={badge} alt=""/>

                                </figure>
                            </div>
                        </Inner>
                    </Container>
                }

            </>

    );
};

export default Promo;